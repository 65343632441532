<template>
  <head>
      <meta charset="UTF-8">
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
  </head>
  <div class="container">
    <router-view/>
  </div>
   <footer>
    <div class="footer">
      <div class="h2-right">
        <p>Made by <a target="_blank" href="http://web-collective.ipumon.com/site/about">IPUMON</a> 2022-23</p>
      </div>
    </div>
  </footer>
  <!-- <loadlog/> -->
</template>
<script>
// import loadlog from '@/components/SLinks';
export default ({
  setup() {
    const url_host = window.location.hostname
    // const url_path = window.location.pathname
    // console.log(url_host, url_path)
    console.log("www.ipumon.com/web-c for more infomation about this site. For policy and site terms vist https://web-c.ipumon.net/terms")
  },
  actions: {
    
  },
  
  // mounted() {
  //     this.$store.dispatch("fetchcontent");
  //     },
})
</script>
