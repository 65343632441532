import { createStore } from 'vuex'
import { doc, getDoc, getDocs, collection, query, where, limit} from 'firebase/firestore'
// import { collection, query, where, updateDoc } from 'firebase/firestore'
import db from '../firebase/firebaseInit.js'
// import { getAuth, fetchSignInMethodsForEmail, EmailAuthProvider} from "firebase/auth";


export default createStore({
  state: {
    // site mode
    sidemode: window.location.hostname,
    // social links 
    LinksData: [],
    sicon_loaded: null,
    // Widget
    WidgetsData: [],
    widgetloaded: null,
    // Media
    MediaData: [],
    medialoaded: null,
    // site
    webcc: null,
  },

  getters: {},

  mutations: {
    // other
    webcContent(state) {
      state.webcc = true
    },

    setContent(state, doc) {
      state.siteId = doc.id
      state.Widget = doc.data().Widget
      state.Widget_Editor = doc.data().Widget
      state.title = doc.data().title
      state.page_column = doc.data().column
      state.page_mediat = doc.data().mediat
      state.page_social = doc.data().social
      state.page_widgett = doc.data().widgett
      state.page_background = doc.data().background
    },
    SetMediaicon(state, payload) {
      state.LinksData.push(payload);
    },
    sicon_loaded(state) {
      state.slinkloaded = true
    },
    SetWidget(state, payload) {
      state.WidgetsData.push(payload);
    },
    widget_loaded(state) {
    state.widgetloaded = true
    },
    SetMedia(state, payload) {
      state.MediaData.push(payload);
      },
    medget_loaded(state) {
    state.medialoaded = true
    },


  },

  actions: {
    async SocialLinkGet ({ commit, state }) {
      const url_host = this.state.sidemode
      const getData = query(collection(db,"Links"), where("site", "==", url_host), limit(8));
      const results = await getDocs(getData);
      results.forEach(doc => {
        if (!state.LinksData.some((Social) => Social.docId === doc.id)) {
          const data = {
            docId: doc.id,
            slinks: doc.data().slinks,
            sicon: doc.data().stype,
          };
        commit("SetMediaicon", data);
      }
      });
      commit('sicon_loaded')
    },
    async WidgetGet ({ commit, state }) {
      const url_host = this.state.sidemode
      const getData = query(collection(db,"Widgets"), where("site", "==", url_host), limit(8));
      const results = await getDocs(getData);
      results.forEach(doc => {
        if (!state.WidgetsData.some((Widgets) => Widgets.docId === doc.id)) {
          const data = {
            docId: doc.id,
            widget: doc.data().widget,
            wid_media_mode: doc.data().wid_media_mode,
            wid_title: doc.data().wid_title,
            wid_media: doc.data().wid_media,
            wid_text: doc.data().wid_text,
          };
        commit("SetWidget", data);
      }
      });
      commit('widget_loaded')
    },
    async MediaGet ({ commit, state }) {
      const url_host =  this.state.sidemode
      const getData = query(collection(db,"Media"), where("site", "==", url_host), limit(8));
      const results = await getDocs(getData);
      results.forEach(doc => {
        if (!state.MediaData.some((media) => media.docId === doc.id)) {
          const data = {
            docId: doc.id,
            img: doc.data().img,
            side: doc.data().side,
          };
        commit("SetMedia", data);
      }
      });
      commit('medget_loaded')
    },
    // content
    async fetchcontent ({commit}) {
      const sitemode = this.state.sidemode
      console.log(sitemode)
      const docRef = doc(db, "Sites", sitemode);
      const docSnap = await getDoc(docRef);   
      commit("setContent", docSnap);
    },
  },
  modules: {
  }
})
