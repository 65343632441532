import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'Not Found',
    redirect: '/'
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    meta: {
      title: window.location.hostname,
    }
  },
  {
    path: '/cv',
    name: 'CV',
    component: () => import('../views/CV-Resume.vue'),
    meta: {
      title: window.location.hostname,
    }
  },
  {
    path: '/resume',
    name: 'Resume',
    component: () => import('../views/CV-Resume.vue'),
    meta: {
      title: window.location.hostname,
    }
  },
  // {
  //   path: '/site/about',
  //   name: 'about',
  //   component: () => import('../views/AboutView.vue')
  // },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to) => {
  // const browserHeaderTitle = to.name
  document.title = to.meta.title ? to.meta.title : 'Web-C IPUMON';
})

export default router
